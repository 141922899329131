var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "dashboard-twitch-page" },
    [
      _c(
        "v-card-title",
        [
          _c("h2", [_vm._v("Twitch Accounts")]),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "orange"
          },
          on: {
            click: function($event) {
              return _vm.add()
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c("v-data-table", {
        ref: "twitchTable",
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.getTwitchAccounts,
          search: _vm.search,
          "item-key": "key"
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(props) {
              return [
                _c(
                  "tr",
                  {
                    key: props.item.key,
                    staticClass: "sortableRow",
                    on: {
                      click: function($event) {
                        props.expanded = !props.expanded
                      }
                    }
                  },
                  [
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(" " + _vm._s(props.item.name) + " ")
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.url))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.apiKey))
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.status || "unavailable"))
                    ]),
                    _c(
                      "td",
                      {
                        staticClass: "text-xs-center",
                        attrs: { width: "250px" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.edit(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openRemoveDialog(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("delete")])],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "pageText",
            fn: function(ref) {
              var pageStart = ref.pageStart
              var pageStop = ref.pageStop
              return [
                _vm._v(
                  " From " + _vm._s(pageStart) + " to " + _vm._s(pageStop) + " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v(" Delete Twitch Account")])],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "Are you sure want to delete the twitch account called "
                ),
                _c("b", [_vm._v(_vm._s(_vm.twitch.name))]),
                _vm._v("?")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove }
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "768px" },
          model: {
            value: _vm.editing,
            callback: function($$v) {
              _vm.editing = $$v
            },
            expression: "editing"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.twitch.name))
                      ]),
                      _c("v-spacer"),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.update } },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      )
                    ],
                    1
                  ),
                  !!_vm.twitch
                    ? _c(
                        "v-flex",
                        { attrs: { "mt-4": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "mr-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWITCH NAME",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.twitch.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.twitch,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "twitch.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWITCH URL",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.twitch.url,
                                              callback: function($$v) {
                                                _vm.$set(_vm.twitch, "url", $$v)
                                              },
                                              expression: "twitch.url"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWITCH API KEY",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.twitch.apiKey,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.twitch,
                                                  "apiKey",
                                                  $$v
                                                )
                                              },
                                              expression: "twitch.apiKey"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { flat: "" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }