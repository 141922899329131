<template>
  <v-card class="dashboard-twitch-page">
    <v-card-title>
      <h2>Twitch Accounts</h2>
      <v-spacer />

      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-btn circle absolute top small right fab color="orange" @click="add()">
      <v-icon>add</v-icon>
    </v-btn>

    <v-data-table
      ref="twitchTable"
      :headers="headers"
      :items="getTwitchAccounts"
      :search="search"
      class="elevation-1"
      item-key="key"
    >
      <template slot="items" slot-scope="props">
        <tr
          :key="props.item.key"
          class="sortableRow"
          @click="props.expanded = !props.expanded"
        >
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">{{ props.item.url }}</td>
          <td class="text-xs-left">{{ props.item.apiKey }}</td>
          <td class="text-xs-left">{{ props.item.status || "unavailable" }}</td>

          <td class="text-xs-center" width="250px">
            <v-btn link color="white" light small fab @click="edit(props.item)">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              link
              color="white"
              light
              small
              fab
              @click="openRemoveDialog(props.item)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        From {{ pageStart }} to {{ pageStop }}
      </template>
    </v-data-table>

    <v-dialog v-model="deleting" max-width="500px">
      <v-card>
        <v-toolbar color="highlight" dark>
          <v-toolbar-title> Delete Twitch Account</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          >Are you sure want to delete the twitch account called
          <b>{{ twitch.name }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="error" @click="remove">Remove</v-btn>
            <v-btn flat class="secondary" @click="deleting = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editing" width="768px">
      <v-card>
        <v-card-text>
          <v-toolbar color="highlight" dark>
            <v-icon dark>edit</v-icon>
            <v-toolbar-title> {{ twitch.name }}</v-toolbar-title>
            <v-spacer />
            <v-spacer />
            <v-btn icon @click="update">
              <v-icon>saves</v-icon>
            </v-btn>
            <v-btn icon @click="editing = false">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-flex mt-4 v-if="!!twitch">
            <v-layout column>
              <v-flex>
                <v-layout row>
                  <v-flex mr-2>
                    <v-text-field
                      label="TWITCH NAME"
                      required
                      v-model="twitch.name"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      label="TWITCH URL"
                      required
                      v-model="twitch.url"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      label="TWITCH API KEY"
                      required
                      v-model="twitch.apiKey"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="primary" @click="update">Save</v-btn>
            <v-btn flat class="secondary" @click="editing = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import "firebase/database"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "Twitch",
  metaInfo: {
    title: "Twitch"
  },
  data() {
    return {
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      twitch: {
        key: 0,
        name: "",
        url: "",
        apiKey: ""
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      tmp: "",
      search: "",
      pagination: {},
      user: this.$store.getters.user,
      headers: [
        {
          text: "NAME",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "URL",
          value: "url",
          align: "left",
          sortable: false
        },
        {
          text: "API KEY",
          value: "apiKey",
          align: "left",
          sortable: false
        },
        {
          text: "IN USE",
          value: "inuse",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters("screenshot", ["getTwitchAccounts"])
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  mounted() {
    this.$refs.twitchTable.defaultPagination.rowsPerPage = 20
  },
  methods: {
    ...mapActions("screenshot", [
      "addTwitchAccount",
      "updateTwitchAccount",
      "removeTwitchAccount"
    ]),
    gotoEvents() {
      this.$router.push("/events")
    },
    resetForm() {
      this.twitch = {
        key: 0,
        name: "",
        url: "",
        apiKey: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(twitch) {
      this.viewing = true
      this.twitch = { ...twitch }
    },
    closeModalDetail() {
      this.viewing = false
      this.twitch = null
    },
    openRemoveDialog(twitch) {
      this.twitch = { ...twitch }
      this.deleting = true
    },
    remove() {
      this.removeTwitchAccount(this.twitch.key)
      this.deleting = false
    },
    edit(twitch) {
      this.editing = true
      this.twitch = { ...twitch }
      this.twitchAccounts = this.getTwitchAccounts
    },
    add() {
      this.editing = true
      this.adding = true
      this.twitch = {
        name: "",
        url: "",
        apiKey: ""
      }
    },
    update() {
      this.twitch.addedBy = this.user.name
      if (this.adding) {
        this.addTwitchAccount(this.twitch)
      } else {
        this.updateTwitchAccount({
          key: this.twitch.key,
          obj: this.twitch
        })
      }
      this.editing = false
      this.adding = false
    },
    save() {
      this.items.push(this.twitch)
      this.resetForm()
      this.adding = false
      console.log("saving")
      this.addTwitchAccount(this.twitch)
    }
  }
}
</script>
